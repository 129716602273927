// @src/components/Modal.jsx

import React from "react"
import "./Modal.scss"
import { RiCloseLine } from "react-icons/ri"

const Modal = ({ setIsOpen }) => {
  return (
    <>
      <div className="darkBG" onClick={() => setIsOpen(false)} />
      <div className="centered">
        <div className="modal">
          <div className="modalHeader">
          </div>
          <div>
            <form
              action="https://newdancetv.us20.list-manage.com/subscribe/post?u=ee430572607e38e0c1def5ff5&amp;id=0c72d9e959"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              class="validate"
              target="_blank"
              novalidate
            >
              <div className="field">
                <label for="mce-EMAIL"></label>
                <input
                  placeholder="Email"
                  type="email"
                  name="EMAIL"
                  id="mce-EMAIL"
                />
              </div>
              <div className="field">
              <label for="mce-MMERGE1"> </label>
	            <input 
              placeholder="Full Name"
              type="text" 
              name="MMERGE1" 
              id="mce-MMERGE1"/>
              </div>
              <div id="mce-responses">
                <div
                  class="response"
                  id="mce-error-response"
                ></div>
                <div
                  class="response"
                  id="mce-success-response"
                ></div>
              </div>
              <div>
                <div>
                  <input
                    className="modalBtn"
                    type="submit"
                    value="Sign Up"
                    name="Sign Up"
                    id="mc-embedded-subscribe"
                  />
                </div>
              </div>
            </form>
          </div>
          <button className="closeBtn" onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent"></div>
        </div>
      </div>
    </>
  )
}

export default Modal