import React, { Component } from "react"
import Slider from "react-slick"

import "./Slider.scss"

import Profile1 from "../images/profile/herder.png"
import Profile2 from "../images/profile/tyrone.png"
import Profile3 from "../images/profile/arthur.png"

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
    }
    return (
      <div className="slider">
        <Slider {...settings}>
          <div className="slider__item">
            <img src={Profile2} alt="Tyrone van der Meer" />
            <p>Tyrone van der Meer / The Notorious IBE Festival</p>
            <h1>
              We love the media involvement of New Dance during our festival.
              Can't wait to see what their app will do for dancers!
            </h1>
          </div>
          <div>
            <img src={Profile3} alt="Arthur Libanio" />
            <p>Artur Libanio / Groove Session</p>
            <h1>
              Pretty stoked to see what New Dance is up to. If the platform is
              as good as their media production, we'll join!
            </h1>
          </div>
        </Slider>
      </div>
    )
  }
}
