import React, { useState } from "react"
import classnames from "classnames"
import { Header, Footer, AnimatedMobile, AnimatedSchool } from "../components"
import * as TimelineImage from "../assets/timeline.svg"
import Modal from "../components/Modal"
import Slider from "../components/Slider"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import TitlePreviewOne from "../images/festivals/ibe.png"
import TitlePreviewTwo from "../images/festivals/cfw.png"
import TitlePreviewThree from "../images/festivals/udd.png"
import TitlePreviewFour from "../images/festivals/bfg.png"
import TitlePreviewFive from "../images/festivals/dli.png"
import TitlePreviewSix from "../images/festivals/wbc.png"
import TitlePreviewSeven from "../images/festivals/sc.png"
import TitlePreviewEight from "../images/festivals/gs.png"

import creator1 from "../images/creators/creator-1.png"
import creator2 from "../images/creators/creator-2.png"
import creator3 from "../images/creators/creator-3.png"
import creator4 from "../images/creators/creator-4.png"
import creator5 from "../images/creators/creator-5.png"
import creator6 from "../images/creators/creator-6.png"
import creator7 from "../images/creators/creator-7.png"
import creator8 from "../images/creators/creator-8.png"
import creator9 from "../images/creators/creator-9.png"
import creator10 from "../images/creators/creator-10.png"

import VideoPlayer from "react-video-js-player"

import "./index.scss"

export default function Home() {
  const [currentFaqItem, setCurrentFaqItem] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Header />

      <div className="hero">
        {typeof window !== "undefined" && (
          <VideoPlayer
            src="https://d3dypiitz28yn5.cloudfront.net/5c5ea270-4c74-4101-a319-ffebc039a395.m3u8"
            onReady={instance => {
              instance.player().muted(true)
              instance.player().playsinline(true)
              instance.player().loop(true)
              instance.player().play()
            }}
            playsinline={true}
            autoPlay={true}
            muted={true}
            controls={false}
            controlsList="nodownload"
            hideControls={["playbackrates"]}
            className="hero__video"
          />
        )}
      </div>

      <div className="quote">
        <div className="layout">
          <div className="value-proposition">
            <div className="value-proposition_dark">
              <div className="layout">
                <div className="value-proposition__inner">
                  <div className="value-proposition__inner__item">
                    <h5>Shooting for the future of dance</h5>
                  </div>
                  <div className="value-proposition__inner__item">
                    <p>
                      Passionate about hip hop and dance since forever, we love
                      to create cool and inspiring dance content. Together with
                      our friends and festival partners we create and share
                      awesome moments through our social media channels and our
                      platform. The platform is a major addition to festivals
                      and dancers who want to build out their audience.
                    </p>
                    <AnchorLink className="AnchorLink" to="/#participate">
                    <button className="primaryBtn">Join</button>
                    </AnchorLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="layout">
        <div className="offer-container">
          <AnimatedSchool/>
          
        </div>
      </div>

      <div className="value-proposition">
        <div className="value-proposition_dark">
          <div className="layout">
            <Slider/>
            <div className="value-proposition__inners">
              <div className="value-proposition__inners__item">
                <h1>Festivals</h1>
                <h2>12</h2>
              </div>
              <div className="value-proposition__inners__item">
                <h1>Impressions</h1>
                <h2>57MIL</h2>
              </div>
              <div className="value-proposition__inners__item">
                <h1>Videos</h1>
                <h2>541</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="library">
        <div className="library__titles">
          <a href="https://www.instagram.com/thenotoriousibe/" target="_blank" rel="noreferrer">
            <img src={TitlePreviewOne} alt="IBE" />
          </a>
          <a href="https://www.instagram.com/crashfestbattle/" target="_blank" rel="noreferrer">
            <img src={TitlePreviewTwo} alt="Crashfest" />
          </a>
          <a href="https://www.instagram.com/urbandansdagen/" target="_blank" rel="noreferrer">
            <img src={TitlePreviewThree} alt="Urban Dansdagen" />
          </a>
          <a href="https://www.instagram.com/internationaldanceleague/" target="_blank" rel="noreferrer">
            <img src={TitlePreviewFive} alt="Dance League" />
          </a>
          <a href="https://www.instagram.com/worldbreakingclassic/" target="_blank" rel="noreferrer">
            <img src={TitlePreviewSix} alt="Soul Cypher" />
          </a>
          <a href="https://www.instagram.com/soulcypherbattle/" target="_blank" rel="noreferrer">
            <img src={TitlePreviewSeven} alt="World Braking Classicr" />
          </a>
          <a href="https://www.instagram.com/_groove_session_/" target="_blank" rel="noreferrer">
            <img src={TitlePreviewEight} alt="Groove Sessions" />
          </a>
        </div>
        <div className="value-proposition">
          <div className="layout">
            <div className="value-proposition__inners">
              <div className="value-proposition__inners__item">
                <h1>Festivals</h1>
                <p>
                  Want to know how we can help you grow your festival? Share
                  your e-mail and we’ll impress you during a quick call.
                </p>
                {/* modal Festivals*/}
                <main>
                  <a href="https://us20.list-manage.com/contact-form?u=ee430572607e38e0c1def5ff5&form_id=7d2f7a7bf3050c34ef15114c3fc7ee6d">
                  <button className="primaryBtn">
                    We want to know more
                  </button>
                  </a>
                </main>
              </div>
              <div className="value-proposition__inners__item">
                <h1>Creators</h1>
                <p>
                  Want to take part in the development of the platform? We
                  only have limited seats available, but let us know
                  below and we’ll stay in touch!
                </p>
                {/* modal Creators */}
                <main>
                  <button
                    className="primaryBtn"
                    onClick={() => setIsOpen(true)}
                  >
                   Send me an invite
                  </button>
                  {isOpen && <Modal setIsOpen={setIsOpen} />}
                </main>
              </div>
            </div>
          </div>
        </div>
        <div className="library__titles">
          <a href="https://www.instagram.com/niektheruggeds/" target="_blank" rel="noreferrer">
            <img
              src={creator1}
              alt="Preview of title (thumbnail of content piece)"
            />
          </a>
          <a href="https://www.instagram.com/ynotism/" target="_blank" rel="noreferrer">
            <img
              src={creator2}
              alt="Preview of title (thumbnail of content piece)"
            />
          </a>
          <a href="https://www.instagram.com/jenniferromen/" target="_blank" rel="noreferrer">
            <img
              src={creator3}
              alt="Preview of title (thumbnail of content piece)"
            />
          </a>
          <a href="https://www.instagram.com/ghettofunkcollective/" target="_blank" rel="noreferrer">
            <img
              src={creator4}
              alt="Preview of title (thumbnail of content piece)"
            />
          </a>
          <a href="https://www.instagram.com/xak_arcopom/" target="_blank" rel="noreferrer">
            <img
              src={creator5}
              alt="Preview of title (thumbnail of content piece)"
            />
          </a>
          <a href="https://www.instagram.com/_deydey_/" target="_blank" rel="noreferrer">
            <img
              src={creator6}
              alt="Preview of title (thumbnail of content piece)"
            />
          </a>
          <a href="https://www.instagram.com/brooke_milliner/" target="_blank" rel="noreferrer">
            <img
              src={creator7}
              alt="Preview of title (thumbnail of content piece)"
            />
          </a>
          <a href="https://www.instagram.com/nicholasmarvel1/" target="_blank" rel="noreferrer">
            <img
              src={creator8}
              alt="Preview of title (thumbnail of content piece)"
            />
          </a>
          <a href="https://www.instagram.com/bboymoy/" target="_blank" rel="noreferrer">
            <img
              src={creator9}
              alt="Preview of title (thumbnail of content piece)"
            />
          </a>
          <a href="https://www.instagram.com/roxrite95/" target="_blank" rel="noreferrer">
            <img
              src={creator10}
              alt="Preview of title (thumbnail of content piece)"
            />
          </a>
        </div>
      </div>

      <div id="participate">
        <AnimatedMobile/>
      </div>

      <div className="faq">
        <div className="layout">
          <div
            className={classnames("faq__item", {
              "faq__item--active": currentFaqItem === 0,
            })}
          >
            <div
              className="faq__item__handle"
              onClick={() => setCurrentFaqItem(currentFaqItem === 0 ? null : 0)}
            >
              <div className="faq__item__handle__title">
                What is New Dance TV?
              </div>
              <div className="faq__item__handle__tooltip">
                {currentFaqItem === 0 ? "click to collapse" : "click to expand"}
              </div>
            </div>
            <div className="faq__item__body">
              <div className="faq__item__body__inner">
                A media company collaborating with dancers, festivals and other
                key scene figures to create and share memorable moments in
                dance. With this collection, brought to the world through our
                social channels and app, we help to create the future of dance.
                Ultimately, we are just suckers for the culture ourselves and
                feel there is a need for a proper platform that helps festivals
                and dancers to grow, connect and professionalize in one
                dedicated place.{" "}
              </div>
            </div>
          </div>
          <div
            className={classnames("faq__item", {
              "faq__item--active": currentFaqItem === 1,
            })}
          >
            <div
              className="faq__item__handle"
              onClick={() => setCurrentFaqItem(currentFaqItem === 1 ? null : 1)}
            >
              <div className="faq__item__handle__title">Who are we?</div>
              <div className="faq__item__handle__tooltip">
                {currentFaqItem === 1 ? "click to collapse" : "click to expand"}
              </div>
            </div>
            <div className="faq__item__body">
              <div className="faq__item__body__inner">
                Passionate about dance, we’re the guys behind the social
                accounts of NewDanceTV and support organizing events such as The
                Notorious IBE, Hip Hop Weekend, World Breaking Classic,
                International Dance League and many more. Next to this we have
                developed the world's biggest street art mapping community
                streetartcities.com. Our team can shoot, edit and develop
                blindfolded.
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}
